import { FC, useEffect, useState } from 'react';
import {
  Form,
  Input,
  InputNumber,
  Checkbox,
  Divider,
  Select,
  Row,
  Col,
  FormInstance,
} from 'antd';

import { conditionOptions } from '../../../../constants/conditionOptions';
import { numberField, requiredField } from '../../../../constants/formRules';
import { statusOptions } from '../../../../constants/statusOptions';
import { translit } from '../../../../utils/translit';
import { LabelField } from '../../../LabelField';
import { RichTextEditor } from '../../../RichTextEditor';
import { getDate } from '1_shared';
import { dictionaries } from '../../../../dictionaries';
import { apiService } from '../../../../services';
import { Item } from '../../../../interfaces/item';

type Props = {
  data: any;
  form: FormInstance;
};

export const ProductGeneralTab: FC<Props> = ({ data, form }) => {
  const [categoryOptions, setCategoryOptions] = useState(
    dictionaries.protuctTypesDictionary.categories,
  );

  const [sparePartFromOptions, setSparePartFromOptions] = useState([]);

  useEffect(() => {
    const getSparePartFromOptions = async () => {
      try {
        const parsingCars = await apiService.GET('/parsing-cars');

        setSparePartFromOptions(parsingCars.map((car: Item) => ({
          label: car.name,
          value: car.id,
        })));
      } catch (error) {
        setSparePartFromOptions([]);
      }
    };

    if (!sparePartFromOptions.length) {
      getSparePartFromOptions();
    }
  }, []);

  const handleNameChange = ({ target: { value } }: any) => {
    const alias = translit(value);
    form.setFieldsValue({ alias });
  };

  const handleTypeChange = (typeIds: string[]) => {
    form.setFieldsValue({ categories: [] });

    if (!typeIds.length) {
      setCategoryOptions(dictionaries.protuctTypesDictionary.categories);

      return;
    }

    const categories = dictionaries.protuctTypesDictionary.categories.filter(
      (category) => typeIds.includes(category.parentId),
    );

    setCategoryOptions(categories);
  };

  return (
    <>
      <Row gutter={40}>
        <Col md={17} xs={24}>
          <Form.Item
            name="name"
            label="Наименование товара*"
            rules={requiredField}
          >
            <Input onChange={handleNameChange} />
          </Form.Item>
          <Form.Item name="alias" label="Url псевдоним*" rules={requiredField}>
            <Input />
          </Form.Item>
          <Form.Item name="introtext" label="Краткое описание">
            <Input />
          </Form.Item>
          <Form.Item name="types" label="Тип товара">
            <Select
              optionFilterProp="label"
              mode="multiple"
              allowClear
              options={dictionaries.protuctTypesDictionary.types}
              onChange={handleTypeChange}
            />
          </Form.Item>
          <Form.Item name="categories" label="Категория товара">
            <Select
              optionFilterProp="label"
              mode="multiple"
              allowClear
              options={categoryOptions}
            />
          </Form.Item>
          <Form.Item name="marks" label="Марки (применяемость по маркам)">
            <Select
              optionFilterProp="label"
              mode="multiple"
              allowClear
              options={dictionaries.carDictionary.brands}
            />
          </Form.Item>
          <Form.Item
            name="applicability"
            label="Модели (применяемость к моделям)"
          >
            <Select
              optionFilterProp="label"
              mode="multiple"
              allowClear
              options={dictionaries.carDictionary.applicabilityOptions}
            />
          </Form.Item>
          <Form.Item name="code" label="Код товара*" rules={requiredField}>
            <Input />
          </Form.Item>
          <Form.Item name="originalNumbers" label="Оригинальные номера">
            <Select
              mode="tags"
              tokenSeparators={[',']}
              notFoundContent={null}
            />
          </Form.Item>
          <Form.Item name="oldOriginalNumbers" label="Старые ориг. номера">
            <Select
              mode="tags"
              tokenSeparators={[',']}
              notFoundContent={null}
            />
          </Form.Item>
          <Row gutter={64}>
            <Col md={12} xs={24}>
              <Form.Item
                name="condition"
                label="Состояние*"
                rules={requiredField}
              >
                <Select options={conditionOptions} />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                name="status"
                label="Статус*"
                rules={requiredField}
              >
                <Select options={statusOptions} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="sparePartFrom"
            label="Запчасть снята с"
            normalize={(value) => !value ? '' : value}
          >
            <Select
              showSearch
              optionFilterProp="label"
              allowClear
              options={sparePartFromOptions}
            />
          </Form.Item>
          <Form.Item name="deliveryTime" label="Сроки поставки">
            <Input />
          </Form.Item>
          <Row gutter={64}>
            <Col md={12} xs={24}>
              <Form.Item name="price" label="Цена" rules={numberField}>
                <InputNumber min={0} />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                name="count"
                label="Кол-во на Ириновском"
                rules={numberField}
              >
                <InputNumber min={0} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={64}>
            <Col md={12} xs={24}>
              <Form.Item name="oldPrice" label="Старая цена" rules={numberField}>
                <InputNumber min={0} />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                name="additionalCount"
                label="Кол-во на Бокситогорской"
                rules={numberField}
              >
                <InputNumber min={0} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={64}>
            <Col md={12} xs={24}>
              <Form.Item name="priceForService" label="Цена замены" rules={numberField}>
                <InputNumber min={0} />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                name="secondAdditionalCount"
                label="Кол-во на Придорожной"
                rules={numberField}
              >
                <InputNumber min={0} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name="manufacturer" label="Производитель">
            <Input />
          </Form.Item>
          <Form.Item name="volume" label="Объем">
            <Input />
          </Form.Item>
        </Col>
        <Col md={7} xs={24}>
          <LabelField label="Дата создания" value={getDate(data.createdAt)} />
          <LabelField label="Кем создан" value={data.createdBy?.name} />
          <LabelField label="Дата изменения" value={getDate(data.changedAt)} />
          <LabelField label="Кем изменен" value={data.changedBy?.name} />
          <Divider />
          <Form.Item name="published" valuePropName="checked" noStyle>
            <Checkbox defaultChecked>Опубликован</Checkbox>
          </Form.Item>
          {/* <Form.Item name="exported" valuePropName="checked">
            <Checkbox>Выгружать во внешние системы</Checkbox>
          </Form.Item> */}
        </Col>
      </Row>
      <Divider />
      <div>
        <h3>Контент</h3>
        <Form.Item name="content" label="Контент" noStyle>
          <RichTextEditor initialValue={data.content} />
        </Form.Item>
      </div>
    </>
  );
};
