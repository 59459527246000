import { useState } from 'react';
import {
  Form,
  Button,
  PageHeader,
  Popconfirm,
} from 'antd';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';

import { commonVerticalLayout } from '../../../constants/formLayout';
import { apiService, notificationService, userService } from '../../../services';
import { Spinner } from '../../../components/Spinner';
import { Pages } from '../../../1_shared/constants/pages';
import { ProductTabs } from '../../../components/Tabs/ProductTabs';
import { useFetchData } from '../../../hooks/useFetchData';
import { Product } from '../../../interfaces';
import { Permissions } from '../../../enums/permissions';
import { ProductStatus } from '../../../enums/status';
import { Conditions } from '../../../enums/conditions';

const getInitialValue = (data: Product) => ({
  avitoData: {
    avitoCategory: 'Запчасти и аксессуары',
  },
  sparePartFrom: '',
  ...data,
  published: data.id ? data.published : true,
  price: data.price || 0,
  oldPrice: data.oldPrice || 0,
  priceForService: data.priceForService || 0,
  count: data.count || 0,
  additionalCount: data.additionalCount || 0,
  secondAdditionalCount: data.secondAdditionalCount || 0,
  deliveryTime: data.deliveryTime || '',
  condition: data.condition || Conditions.BU,
  status: data.status || ProductStatus.STOCK,
});

export const ProductEditPage = () => {
  const navigate = useNavigate();
  const param: any = useParams();
  const location = useLocation();
  const { product } = location.state ?? {};
  const [form] = Form.useForm();

  const [isSaveBtnDisabled, setSaveBtnDisabled] = useState<boolean>(false);

  const { isLoading: isDataLoading, data = {} } = useFetchData(`/products/${param.id}`);

  const isNew: boolean = !param.id;

  const subTitle: string = isNew ? 'Создание товара' : 'Редактирование товара';

  if (!isNew && isDataLoading) {
    return <Spinner />;
  }

  const handleCancel = () => {
    navigate(Pages.PRODUCTS);
  };

  const handleSave = async () => {
    try {
      setSaveBtnDisabled(true);

      await form.validateFields();
      const body = form.getFieldsValue();

      if (isNew) {
        const requestedData = await apiService.POST('/products', body);

        navigate(`${Pages.PRODUCTS}/${requestedData.id}`);
      } else {
        await apiService.PATCH(`/products/${data.id}`, body);
        navigate(`${Pages.PRODUCTS}`);
      }
      notificationService.notifySuccess('Товар успешно сохранен');
    } catch (error) {
      notificationService.notifyError(error);
    } finally {
      setSaveBtnDisabled(false);
    }
  };

  const handleDelete = async () => {
    try {
      setSaveBtnDisabled(true);
      await apiService.DELETE(`/products/${data.id}`);
      navigate(Pages.PRODUCTS);
      notificationService.notifySuccess('Товар успешно удален');
    } catch (error) {
      notificationService.notifyError(error);
    } finally {
      setSaveBtnDisabled(false);
    }
  };

  return (
    <>
      <PageHeader
        className="page-header"
        title="Товары"
        subTitle={subTitle}
        extra={[
          <Popconfirm
            key='delete-btn'
            title="Вы действительно хотите удалить товар?"
            onConfirm={handleDelete}
            disabled={isNew || !userService.hasAccess([Permissions.SUPER_ADMIN, Permissions.ADMIN])}
            okText="Да"
            cancelText="Нет"
          >
            <Button
              disabled={isNew || !userService.hasAccess([Permissions.SUPER_ADMIN, Permissions.ADMIN])}
              danger
            >
              Удалить
            </Button>
          </Popconfirm>,
          <Button
            key="3"
            disabled={isNew || !userService.hasAccess([Permissions.SUPER_ADMIN, Permissions.ADMIN])}
            type="dashed"
          >
            <Link to={Pages.PRODUCT_CREATE} state={{ product: data }}>
              Копировать
            </Link>
          </Button>,
          <Button key="2" onClick={handleCancel}>
            {isNew ? 'Отмена' : 'Закрыть'}
          </Button>,
          <Button
            key="1"
            type="primary"
            disabled={isSaveBtnDisabled || !userService.hasAccess([Permissions.SUPER_ADMIN, Permissions.ADMIN])}
            onClick={handleSave}
          >
            Сохранить
          </Button>,
        ]}
      />
      <div className='page-content'>
        <Form
          className="mg-t-16"
          form={form}
          labelAlign="left"
          requiredMark={false}
          initialValues={getInitialValue(product ?? data)}
          layout="vertical"
          {...commonVerticalLayout}
          disabled={!userService.hasAccess([Permissions.SUPER_ADMIN, Permissions.ADMIN])}
        >
          <ProductTabs generalTabProps={{ data: product ?? data, form }} />
        </Form>
      </div>
    </>
  );
};
